import windowCheck from 'utils/helpers/window-check';

import FontIcon from 'components/icons';

import './styles/scroll-to-top.scss';

type StickyCtaProps = {
    className: string,
  }

const ScrollToTop = ({ className }:StickyCtaProps) => (
  <button 
    type="button" 
    className={ `scroll-to-top-section ${className || ''}` } 
    onClick={  () => windowCheck && window.scrollTo({ top: 0, behavior: 'smooth' }) }>
    <FontIcon icon="arrow-up" />
  </button>
);


export default ScrollToTop;