import { useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/blog';

import useOnScreen from 'utils/hooks/use-onscreen';

import Yoast from 'components/globals/yoast';
import { HeroBlogFeaturedArticle } from 'components/hero/blog-featured-article';
import PaginationPosts from 'components/posts/pagination-posts';
import ScrollToTop from 'components/scroll-to-top/scroll-to-top';

export default function BlogCategory({ pageContext }) {

  const element = useRef(null);
  const isVisible = useOnScreen(element);
  const hero = pageContext.data.acf.featured_article_static;
  let blogPosts;

  const data = useStaticQuery(graphql`
  {
    forYourIndustry: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "for-your-industry"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    futureOfWork: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "future-of-work"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    humanResources: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "human-resources"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    laborLaws: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "labor-laws"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    leadership: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "leadership"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    midMarket: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "mid-market"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    businessGrowth: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "business-growth"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    peopleManagement: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "people-management"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    pressRoom: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "press-room"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    productBlog: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "product-blog"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    productUpdates: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "product-updates"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    productivity: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "productivity"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    restaurantsCafesBarsCoffeeShops: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "restaurants-cafesbars-coffeeshops"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    schedulingStrategy: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "scheduling-strategy"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    smallBusiness: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "small-business"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    templatesResources: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "templates-resources"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
    payrollResources: allWordpressPost(
      filter: {categories: {elemMatch: {slug: {eq: "payroll-resources"}}}}
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          title
          excerpt
          date(formatString: "MMM DD, YYYY")
          featured_media {
            source_url
          }
        }
      }
    }
  }  
`);

  switch (pageContext.blogCategory) {
    case 'for-your-industry':
      blogPosts = data.forYourIndustry;
      break;
    case 'future-of-work':
      blogPosts = data.futureOfWork;
      break;
    case 'human-resources':
      blogPosts = data.humanResources;
      break;
    case 'labor-laws':
      blogPosts = data.laborLaws;
      break;
    case 'leadership':
      blogPosts = data.leadership;
      break;
    case 'mid-market':
      blogPosts = data.midMarket;
      break;
    case 'business-growth':
      blogPosts = data.businessGrowth;
      break;
    case 'people-management':
      blogPosts = data.peopleManagement;
      break;
    case 'press-room':
      blogPosts = data.pressRoom;
      break;
    case 'product-blog':
      blogPosts = data.productBlog;
      break;
    case 'product-updates':
      blogPosts = data.productUpdates;
      break;
    case 'productivity':
      blogPosts = data.productivity;
      break;
    case 'restaurants-cafes-bars-coffee-shops':
      blogPosts = data.restaurantsCafesBarsCoffeeShops;
      break;
    case 'scheduling-strategy':
      blogPosts = data.schedulingStrategy;
      break;
    case 'small-business':
      blogPosts = data.smallBusiness;
      break;
    case 'templates-resources':
      blogPosts = data.templatesResources;
      break;
    case 'payroll-resources':
      blogPosts = data.payrollResources;
      break;
    default:
      undefined;
  }

  return (
    <Layout activeCategory={ pageContext.blogCategory }>
      <Yoast { ...pageContext.data.yoast_head_json } />
      <div className={ `cat-${pageContext.blogCategory}-page-container` } data-signal={ pageContext.wordpress_id }>
        {hero && <HeroBlogFeaturedArticle { ...pageContext.data.acf.featured_article_static } ref={ element } />}
        {typeof blogPosts !== 'undefined' && <PaginationPosts { ...blogPosts } />}
        <ScrollToTop className={ `${!isVisible ? 'is-viewable' : ''}` } />
      </div>
    </Layout>
  );
}