import { forwardRef, useEffect, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ACFImage from 'types/ACFImage';

import Link from 'components/link/link';

import './styles/blog-featured-article.scss';

type Category = {
  category_link: string
  category_title: string
}

type HeroBlogFeaturedArticleProps = {
  background_image: Image
  categories: Category[]
  copy: string
  link_text: string
  link_url: string
  title: string
}

type Image = {
  desktop: ACFImage
  mobile: ACFImage
}

export const HeroBlogFeaturedArticle =  forwardRef(function blogFeaturedArticle(props:HeroBlogFeaturedArticleProps, ref:any) {

  const [showFeaturedArticle, setShowFeaturedArticle] = useState(true);
  const breakpoints = useBreakpoint();

  useEffect(() => {
    Object.values(props).map(featuredArticle => {
      featuredArticle === '' && setShowFeaturedArticle(false);
    });
  }, []);

  return (
    <section
      ref={ ref }
      className={ `blog-featured-article-section offset-top section ${showFeaturedArticle ? '' : 'is-hidden'}` }
      style={ { backgroundImage: !breakpoints.sm ? `url(${props.background_image.desktop.source_url})` : 'none' } }
    >
      {breakpoints.sm && (<img
        src={ props.background_image.mobile.source_url }
        alt="Blog Featured Article"
      />)}
      <div className="container">
        <div className="is-mobile">
          <div className="box">
            <Link
              className="button is-dark is-medium featured-tag"
              to={ props.link_url }
            >
              {props.link_text}
            </Link>

            <h3 className="title">{props.title}</h3>
            <p className="copy">{props.copy}</p>

            <div className="entry-category">
              {props.categories.map((category, index) => (
                <Link
                  key={ index }
                  rel="category tag"
                  to={ category.category_link }
                >
                  {category.category_title}{' '}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});