import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import windowCheck  from 'utils/helpers/window-check';

import PageTop from 'components/posts/page-top';
import PostCard from 'components/posts/post-card';

import 'components/posts/styles/pagination-posts.scss';

const PaginationPosts = posts => {

  const getCurrentPage = () => {
    if (windowCheck) {
      const params = new URLSearchParams(location.search);
      return parseInt((params.get('page')) || '1');
    }
    
    return 1;
  };

  const cardsPerPage = 9;
  const scrollToPage = getCurrentPage() - 1;

  const [numCards, updateNumCards] = useState(cardsPerPage * getCurrentPage());
  const [currentPage, updateCurrentPage] = useState(scrollToPage);
  const [nextPage, updateNextPage] = useState(2);
  const pageRefs = useRef<Array<HTMLHRElement | null>>([]);

  const updatePageRefs = ref => {
    pageRefs.current.push(ref);
  };

  useEffect(() => {
    if (scrollToPage && window) {
      const pageTop = pageRefs.current[scrollToPage]?.getBoundingClientRect().top;
      const scrollToLocation = pageTop ? window.scrollY + pageTop - 100 : 0;
      window.scrollTo({ top: scrollToLocation });
    }
    updateNextPage(getCurrentPage() + 1);
  }, []);

  useEffect(() => {
    window.history.replaceState({}, '', getUrl());
  }, [currentPage]);

  const getUrl = (pageChange = 0) => {
    const target = currentPage + pageChange;
    if (!windowCheck || target < 0 || target * cardsPerPage > posts.edges.length) {
      return '';
    }

    const url = new URL(location.href);
    if (target === 0) {
      url.searchParams.delete('page');
    } else {
      url.searchParams.set('page', String(target + 1));
    }

    return url.href;
  };

  const unWantedCategories = /(Newsletter|Articles)/;
  const postsShown = posts.edges.slice(0, numCards);
  const cards = postsShown.map(({ node }) => {
    const categories = node.categories.filter(str => !unWantedCategories.test(str.name));
    return {
      slug: `/blog/${node.slug}`,
      source_url: node?.featured_media?.source_url ?? 'https://marketing-assets.wheniwork-production.com/2021/12/11223232/generic_blog_image_post_header.jpg',
      name: categories[0]?.name,
      title: node.title,
      excerpt: node.excerpt,
      date: node.date,
    };
  });

  const loadMore = e => {
    e.preventDefault();
    updateNextPage(nextPage + 1);
    updateNumCards(numCards + cardsPerPage);
  };

  const renderCards = cards => {
    const cardHtml: any = [];
    for (let i = 0; i < cards.length; i++) {
      if (i % cardsPerPage === 0) { // start of a page
        const pageNum = i / cardsPerPage;
        cardHtml.push(<PageTop
          key={ pageNum }
          pageNum={ pageNum }
          updatePage={ updateCurrentPage }
          updateRefs={ updatePageRefs }
        />);
      }
      cardHtml.push(<PostCard
        key={ cards[i].slug }
        card={ cards[i] }
      />);
    }

    return cardHtml;
  };

  const getNextPage = () => {
    if (windowCheck) {
      const params = new URLSearchParams(location.search);
      params.set('page', String(nextPage));
      
      return `?${ params.toString() }`;
    }

    return `?page=${ nextPage }`;
  };

  let currentUrl = '';
  if (windowCheck) {
    currentUrl = `https://wheniwork.com${location.pathname}${location.search}`;
  }

  return (
    <section className="section posts-section" data-section="blogPosts">
      { currentUrl &&
        <Helmet>
          <link
            rel="canonical"
            href={ currentUrl }
            data-baseprotocol="https:"
            data-basehost={ `${process.env.GATSBY_SITE_URL}` }
          />
          <meta property="og:url" content={ currentUrl } />
          { getUrl(-1) && <link rel="prev" href={ getUrl(-1) } /> }
          { getUrl(1) && <link rel="next" href={ getUrl(1) } /> }
        </Helmet>
      }
      <div className="container">
        <div className="columns is-variable is-5">
          { renderCards(cards) }
        </div>
        <div className="load-more-container">
          { numCards < posts.edges.length &&
            <a onClick={ e => loadMore(e) } href={ getNextPage() } className="button is-green is-rounded load-more-button">Load More</a>
          }
          <div>{ `${ Math.min(numCards, posts.edges.length) } of ${ posts.edges.length }` }</div>
        </div>
      </div>
    </section>
  );
};

export default PaginationPosts;
