import Link from 'components/link/link';

import './styles/post-card.scss';

type PostCardProps = {
  card: {
    date?: string,
    name: string,
    slug: string,
    source_url: string,
    title: string,
  }
};


const PostCard = ({ card: { slug, source_url, name, title, date } }: PostCardProps) => (
  <div className="column post-container is-one-third-desktop is-half-tablet">
    <div className="card">
      <header className="card-image">
        <figure className="image is-2by1">
          <img
            loading="lazy"
            src={ source_url ?? 'https://marketing-assets.wheniwork-production.com/2021/12/11223232/generic_blog_image_post_header.jpg' }
            alt="Article Image"
          />
        </figure>
      </header>
      <div className="card-content">
        <div className="card-date-category-container is-uppercase">
          <time>{date}</time>
          <span className="date-category-separator">/</span>
          <span dangerouslySetInnerHTML={ { __html: name } } />
        </div>
        <h3
          className="title has-margin-top-md"
          dangerouslySetInnerHTML={ { __html: title } }
        />
      </div>
      <footer className="card-footer">
        <div className="card-button">
          <Link
            className="button is-rounded"
            to={ slug }
          >
              Read Article
          </Link>
        </div>
      </footer>
    </div>
  </div>
);

export default PostCard;