import { useEffect, useRef } from 'react';

import useOnScreen from 'utils/hooks/use-onscreen';

type PageTopProps = {
  pageNum: number,
  updatePage: any,
  updateRefs: any,
};

const PageTop = ({ pageNum, updatePage, updateRefs }: PageTopProps) => {
  const elementRef = useRef(null);
  const isVisible = useOnScreen(elementRef, 1);

  useEffect(() => {
    updateRefs(elementRef.current);
  }, []);
  
  useEffect(() => {
    if (isVisible && updatePage) {
      updatePage(pageNum);
    }
  }, [isVisible]);

  return <hr ref={ elementRef } className="is-full column p-0 m-0 page-top" />;
};

export default PageTop;